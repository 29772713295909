<template>
    <div
        :class="{
            'navbar-layout-container': display.mdAndUp,
            'navbar-layout-container mt-10': display.smAndDown,
        }"
    >
        <!-- Esenda logo/Hubpay logo -->
        <div class="navbar-logo-container">
            <!-- TODO update hubpay logo -->
            <v-img
                v-if="pStore.getIsHubpay"
                class="navbar-logo-hubpay-img"
                :src="getImageUrl('hubpay.svg')"
                alt="Hubpay logo"
            />
            <v-img
                v-else
                class="navbar-logo-esenda-img"
                :src="getImageUrl('esenda.png')"
                alt="Esenda logo"
            />
        </div>

        <!-- Menu list -->
        <v-list color="transparent" class="navbar-menu-items-container">
            <v-list-item-group>
                <v-list-item v-for="(item, i) in filteredRoutes" :key="i">
                    <div
                        :class="{
                            'navbar-menu-item-active': isParentItemActive(
                                item.route,
                            ),
                            'navbar-menu-item': !isParentItemActive(item.route),
                        }"
                        @click="
                            item.subitems
                                ? toggleSubItems(i)
                                : changeRoute(item.route)
                        "
                    >
                        <v-img
                            :class="{
                                'navbar-item-icon-active': isParentItemActive(
                                    item.route,
                                ),
                                'navbar-item-icon': !isParentItemActive(
                                    item.route,
                                ),
                            }"
                            :src="item.iconPath"
                        />
                        <span class="navbar-menu-item-text h200">{{
                            item.title
                        }}</span>
                        <img
                            v-if="item.subitems"
                            :class="{
                                'navbar-item-icon-arrow-expanded':
                                    isSubmenuExpanded && expandedIndex === i,
                                'navbar-item-icon-arrow-collapsed':
                                    !isSubmenuExpanded || expandedIndex !== i,
                            }"
                            :src="getImageUrl('icons/12x12/ic12-arrow-up.svg')"
                        />
                    </div>
                    <!-- Subitems -->
                    <v-list
                        v-if="
                            item.subitems &&
                            isSubmenuExpanded &&
                            expandedIndex === i
                        "
                        color="transparent"
                    >
                        <v-list-item
                            v-for="(subitem, j) in item.subitems"
                            :key="j"
                        >
                            <div
                                :class="{
                                    'navbar-submenu-item-active':
                                        subitem.route === path,
                                    'navbar-submenu-item':
                                        subitem.route !== path,
                                }"
                                @click="changeRoute(subitem.route)"
                            >
                                <v-img
                                    :src="subitem.iconPath"
                                    :class="{
                                        'navbar-submenu-item-icon-active':
                                            subitem.route === path,
                                        'navbar-submenu-item-icon':
                                            subitem.route !== path,
                                    }"
                                />
                                <span class="navbar-menu-item-text h200">{{
                                    subitem.title
                                }}</span>
                            </div>
                        </v-list-item>
                    </v-list>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </div>

    <!-- Logout button -->
    <div id="footer">
        <div
            class="navbar-logout-container"
            data-cy="logoutButton"
            @click="handleLogout()"
        >
            <div class="navbar-logout-button">
                <v-img
                    class="navbar-item-icon"
                    :src="getImageUrl('icons/24x24/ic24-log-out.svg')"
                ></v-img>
                <span class="navbar-logout-text h200"> Logout </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import { computed, ref, watch, onMounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useDisplay } from "vuetify";
import { usePiniaStore } from "@/stores/store";
import {
    navSchoolRoutes,
    navRegistrationRoutes,
    checkFirstTwoRouteSegmentsMatch,
} from "@/methods/navbarRoutes";
import { Roles } from "@/constants/access";
import { getImageUrl } from "@/methods/iconHelpers";
import type { Route } from "@/types/routes";

const pStore = usePiniaStore();
const { logout } = useAuth0();
const router = useRouter();
const route = useRoute();
const display = ref(useDisplay());
const permissions = computed(() => pStore.getPermissions);

const schoolRoutes: Route[] = navSchoolRoutes;
const registrationRoutes: Route[] = navRegistrationRoutes;
const expandedIndex = ref<number | null>(null);
const isSubmenuExpanded = ref(false);

const school = computed(() => pStore.getSchoolSettings);
const hasOrganisationRole = computed(() =>
    pStore.getUserRoles.includes(Roles.ORGANISATION_ROLE),
);

const hasPermissions = (requiredPermissions) => {
    /** Check if the user has all required permissions */
    return requiredPermissions.every((permission) =>
        permissions.value.includes(permission),
    );
};

const filteredRoutes = computed(() => {
    let filtered = hasOrganisationRole.value
        ? schoolRoutes
        : registrationRoutes;

    if (hasOrganisationRole.value) {
        if (!school.value.uses_wcbs) {
            filtered = filtered.filter((obj) => obj.title !== "Posting report");
        }
        if (pStore.getSchoolShortcodes[0] !== "sandroyd") {
            filtered = filtered.filter((obj) => obj.title !== "Pass finance");
        }
        if (!school.value.uses_bacs) {
            filtered = filtered.filter(
                (obj) => obj.title !== "BACS" && obj.title !== "Users",
            );
        }

        /** Filter out routes based on the requiredPermissions */
        filtered = filtered.filter((route) => {
            // Check the main route's permissions
            if (
                route.requiredPermissions.length === 0 ||
                hasPermissions(route.requiredPermissions)
            ) {
                // Filter subitems if they exist
                if (route.subitems) {
                    // Check the subitem's permissions
                    route.subitems = route.subitems.filter(
                        (subitem) =>
                            subitem.requiredPermissions.length === 0 ||
                            hasPermissions(subitem.requiredPermissions),
                    );
                }
                return true;
            }
            return false;
        });
    }
    return filtered;
});

const path = computed(() => route.path);

const changeRoute = (path: string) => {
    router.push(path).catch((err) => {
        console.log("Route error: " + err);
    });
};

const toggleSubItems = (index: number) => {
    expandedIndex.value = expandedIndex.value === index ? null : index;
    isSubmenuExpanded.value = expandedIndex.value !== null;
};

const isParentItemActive = (itemRoute: string) => {
    return checkFirstTwoRouteSegmentsMatch(itemRoute, path.value);
};

const handleLogout = () =>
    logout({
        logoutParams: {
            returnTo: window.location.origin,
        },
    });

// Ensure submenu is expanded when component mounts if the current route is a subitem route
onMounted(async () => {
    setTimeout(() => {
        filteredRoutes.value.forEach((item, index) => {
            if (
                item.subitems &&
                item.subitems.some((subitem) => subitem.route === path.value)
            ) {
                expandedIndex.value = index;
                isSubmenuExpanded.value = true;
            }
        });
    }, 1000);
});

watch(path, () => {
    // Close submenu if the current path doesn't match any of the submenu routes
    if (expandedIndex.value !== null && !isSubmenuExpanded.value) {
        expandedIndex.value = null;
    }
});
</script>
