import { RouteRecordRaw } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

const schoolRoutes: Array<RouteRecordRaw> = [
    {
        path: "/school",
        name: "School",
        component: () => import("@/views/school/SchoolLayout.vue"),
        beforeEnter: authGuard,
        meta: {
            admin: false,
            school: true,
        },
        // all the child routes will be rendered inside SchoolLayout's <router-view>
        // and will be available under the /school namespace
        children: [
            {
                path: "overview",
                name: "SchoolOverview",
                component: () => import("@/views/school/overview/Overview.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
            {
                path: "invoices",
                name: "Invoices",
                component: () => import("@/views/school/invoices/Invoices.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
            {
                path: "transactions",
                name: "Transactions",
                component: () =>
                    import("@/views/school/transactions/Transactions.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
                children: [
                    {
                        path: "invoice-payments",
                        name: "InvoicePayments",
                        beforeEnter: authGuard,
                        component: () =>
                            import(
                                "@/views/school/transactions/categories/Invoice.vue"
                            ),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "extras",
                        name: "Extras",
                        beforeEnter: authGuard,
                        component: () =>
                            import(
                                "@/views/school/transactions/categories/Extras.vue"
                            ),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "donations",
                        name: "Donations",
                        beforeEnter: authGuard,
                        component: () =>
                            import(
                                "@/views/school/transactions/categories/Donations.vue"
                            ),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "direct-debits",
                        name: "DirectDebitsTransactions",
                        beforeEnter: authGuard,
                        component: () =>
                            import(
                                "@/views/school/transactions/categories/DirectDebits.vue"
                            ),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "direct-credit-payroll",
                        name: "DirectCreditPayrollTransactions",
                        beforeEnter: authGuard,
                        component: () =>
                            import(
                                "@/views/school/transactions/categories/DirectCreditPayroll.vue"
                            ),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "direct-credit-supplier",
                        name: "DirectCreditSupplierTransactions",
                        beforeEnter: authGuard,
                        component: () =>
                            import(
                                "@/views/school/transactions/categories/DirectCreditSupplier.vue"
                            ),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                ],
            },
            {
                path: "support",
                name: "Support",
                component: () => import("@/views/school/support/Support.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
            {
                path: "profile",
                name: "Profile",
                component: () => import("@/views/school/profile/Profile.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
            {
                path: "settings",
                name: "Settings",
                component: () => import("@/views/school/settings/Settings.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
            {
                path: "connected-banks",
                name: "AISLayout",
                component: () => import("@/views/school/ais/AISLayout.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
            {
                path: "connected-banks/banks",
                name: "AISBanks",
                component: () => import("@/views/school/ais/banks/Banks.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
            {
                path: "connected-banks/confirmation",
                name: "AISConfirmation",
                component: () =>
                    import("@/views/school/ais/confirmation/Confirmation.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
            {
                path: "gocardless",
                name: "GoCardlessLayout",
                component: () =>
                    import("@/views/school/gocardless/GoCardlessLayout.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
                // all the child routes will be rendered inside GoCardlessLayout's <router-view>
                // and will be available under the /school/gocardless/ namespace
                children: [
                    {
                        path: "overview",
                        name: "GoCardlessOverview",
                        component: () =>
                            import(
                                "@/views/school/gocardless/overview/Overview.vue"
                            ),
                        beforeEnter: authGuard,
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "onboarding",
                        name: "GoCardlessOnboarding",
                        component: () =>
                            import(
                                "@/views/school/gocardless/onboarding/Onboarding.vue"
                            ),
                        beforeEnter: authGuard,
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "setup",
                        name: "GoCardlessSetup",
                        component: () =>
                            import("@/views/school/gocardless/setup/Setup.vue"),
                        beforeEnter: authGuard,
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                ],
            },
            {
                path: "export",
                name: "ExportLayout",
                component: () =>
                    import("@/views/school/export/ExportLayout.vue"),
                beforeEnter: authGuard,
                // all the child routes will be rendered inside ExportLayout's <router-view>
                // and will be available under the /school/export/ namespace
                children: [
                    {
                        path: "pass-finance",
                        name: "PassFinanceData",
                        component: () =>
                            import(
                                "@/views/school/export/passfinance/PassFinanceData.vue"
                            ),
                        beforeEnter: authGuard,
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "pass-finance/import-instructions",
                        name: "PassFinanceImportInstructions",
                        component: () =>
                            import(
                                "@/views/school/export/passfinance/PassFinanceImportInstructions.vue"
                            ),
                        beforeEnter: authGuard,
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                ],
            },
            {
                path: "payment-request-links",
                name: "PaymentRequestLinks",
                component: () =>
                    import(
                        "@/views/school/payment-request-links/PaymentRequestLinks.vue"
                    ),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
            {
                path: "posting-report",
                name: "Postings",
                component: () => import("@/views/school/postings/Postings.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
            {
                path: "bacs",
                name: "BACS",
                beforeEnter: authGuard,
                component: () => import("@/views/school/bacs/Payments.vue"),
                meta: {
                    admin: false,
                    school: true,
                },
                children: [
                    {
                        path: "mandates",
                        name: "Mandates",
                        beforeEnter: authGuard,
                        component: () =>
                            import(
                                "@/views/school/bacs/categories/Mandates.vue"
                            ),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "direct-debits",
                        name: "DirectDebits",
                        beforeEnter: authGuard,
                        component: () =>
                            import(
                                "@/views/school/bacs/categories/DirectDebit.vue"
                            ),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "direct-credit-supplier",
                        name: "Supplier",
                        beforeEnter: authGuard,
                        component: () =>
                            import(
                                "@/views/school/bacs/categories/DirectCreditSupplier.vue"
                            ),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "direct-credit-payroll",
                        name: "Payroll",
                        beforeEnter: authGuard,
                        component: () =>
                            import(
                                "@/views/school/bacs/categories/DirectCreditPayroll.vue"
                            ),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                    {
                        path: "files",
                        name: "Files",
                        beforeEnter: authGuard,
                        component: () =>
                            import("@/views/school/bacs/categories/Files.vue"),
                        meta: {
                            admin: false,
                            school: true,
                        },
                    },
                ],
            },
            {
                path: "users",
                name: "Users",
                component: () => import("@/views/school/users/Users.vue"),
                beforeEnter: authGuard,
                meta: {
                    admin: false,
                    school: true,
                },
            },
        ],
    },
];

export default schoolRoutes.map((route) => {
    return { ...route };
});
