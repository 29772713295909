import { defineStore } from "pinia";
import { SchoolSettings, PostingCustomOptions } from "@/types/school";
import {
    CompanyDetails,
    AccountUsage,
    Stakeholder,
    personalAddressAttributes,
    Documents,
    BankAccount,
} from "@/types/registration";

export const usePiniaStore = defineStore("store", {
    state: () => ({
        userRoles: [] as string[],
        permissions: [] as string[],
        schoolShortcodes: [] as string[],
        isHubpay: false,
        dateRange: [
            new Date(new Date().setDate(new Date().getDate() - 7)),
            new Date(),
        ],
        singleDate: new Date(),
        schoolSettings: {
            base_currency_code: "",
            base_currency_symbol: "",
            currencies: [""],
            logo: "",
            name: "",
            post_to_wcbs: "",
            shortcode: "",
            uses_wcbs: true,
            uses_bacs: false,
            wcbs_on_prem: false,
            show_s3_upload_api_keys: true,
            additional_posting_details: {
                timezone_location: "",
                wcbs_api_available: false,
                current_financial_year_start_date: "",
                current_financial_year_end_date: "",
                current_financial_year_code: "",
                current_financial_period: "",
                number_of_financial_periods_per_year: "",
                eligible_for_automatic_posting_method: false,
                calculated_post_until_date: "",
                calculated_current_financial_period: "",
            },
            role: [],
            permissions: [],
        } as SchoolSettings,
        // Invoices
        loadingTx: true,
        invoiceTxList: [],
        // Payment links
        showPaymentLinkForm: false,
        reloadLinkList: false,
        // Postings
        loadingPostings: true,
        postingsList: [],
        // Posting settings
        postingCustomOptions: {
            posting_status: "",
            post_until_date: "",
        },
        isAuthenticated: false,
        selectedSupplierAccount: null as string | null,
        selectedPayrollAccount: null as string | null,
        selectedMandateAccount: null as string | null,
        refreshUsers: false,
        registrationStep: 0,
        registrationCompanyDetails: null as CompanyDetails | null,
        validateCompanyDetails: false,
        registrationAccountUsage: null as AccountUsage | null,
        validateAccountUsage: false,
        hideRegistrationControls: false,
        showStakeholdersControls: true,
        stakeholders: [] as Stakeholder[],
        address: null as personalAddressAttributes | null,
        registrationDocuments: null as Documents | null,
        validateDocuments: false,
        refreshBacsFiles: false,
        submitRegistrationApplication: false,
        registrationBankAccounts: [] as BankAccount[],
        loadingRegistration: false,
        refreshBacsTable: false,
    }),
    getters: {
        getUserRoles: (state) => state.userRoles,
        getSchoolShortcodes: (state) => state.schoolShortcodes,
        getPermissions: (state) => state.permissions,
        getIsHubpay: (state) => state.isHubpay,
        getSchoolSettings: (state) => state.schoolSettings,
        getDateRange: (state) => state.dateRange,
        getSingleDate: (state) => state.singleDate,
        getLoadingTx: (state) => state.loadingTx,
        getInvoiceTxList: (state) => state.invoiceTxList,
        getShowPaymentLinkForm: (state) => state.showPaymentLinkForm,
        getReloadLinkList: (state) => state.reloadLinkList,
        getLoadingPostings: (state) => state.loadingPostings,
        getPostingsList: (state) => state.postingsList,
        getPostingCustomOptions: (state) => state.postingCustomOptions,
        getIsAuthenticated: (state) => state.isAuthenticated,
        getSelectedSupplierAccount: (state) => state.selectedSupplierAccount,
        getSelectedPayrollAccount: (state) => state.selectedPayrollAccount,
        getSelectedMandateAccount: (state) => state.selectedMandateAccount,
        getRefreshUsers: (state) => state.refreshUsers,
        getRegistrationStep: (state) => state.registrationStep,
        getRegistrationCompanyDetails: (state) =>
            state.registrationCompanyDetails,
        getValidateCompanyDetails: (state) => state.validateCompanyDetails,
        getRegistrationAccountUsage: (state) => state.registrationAccountUsage,
        getValidateAccountUsage: (state) => state.validateAccountUsage,
        getHideRegistrationControls: (state) => state.hideRegistrationControls,
        getShowStakeholdersControls: (state) => state.showStakeholdersControls,
        getStakeholders: (state) => state.stakeholders,
        getAddress: (state) => state.address,
        getRegistrationDocuments: (state) => state.registrationDocuments,
        getValidateDocuments: (state) => state.validateDocuments,
        getRefreshBacsFiles: (state) => state.refreshBacsFiles,
        getSubmitRegistrationApplication: (state) =>
            state.submitRegistrationApplication,
        getRegistrationBankAccounts: (state) => state.registrationBankAccounts,
        getLoadingRegistration: (state) => state.loadingRegistration,
        getRefreshBacsTable: (state) => state.refreshBacsTable,
    },
    actions: {
        setUserRoles(roles: string[]) {
            this.userRoles = roles;
        },
        setSchoolShortcodes(shortcodes: string[]) {
            this.schoolShortcodes = shortcodes;
        },
        setPermissions(permissions: string[]) {
            this.permissions = permissions;
        },
        setIsHubpay(status: boolean) {
            this.isHubpay = status;
        },
        setSchoolSettings(settings: SchoolSettings) {
            this.schoolSettings = settings;
        },
        setDateRange(range: Date[]) {
            this.dateRange = range;
        },
        setSingleDate(date: Date) {
            this.singleDate = date;
        },
        setLoadingTx(status: boolean) {
            this.loadingTx = status;
        },
        setInvoiceTxList(txList: []) {
            this.invoiceTxList = txList;
        },
        setShowPaymentLinkForm(status: boolean) {
            this.showPaymentLinkForm = status;
        },
        setReloadLinkList(status: boolean) {
            this.reloadLinkList = status;
        },
        setLoadingPostings(status: boolean) {
            this.loadingPostings = status;
        },
        setPostingsList(pList: []) {
            this.postingsList = pList;
        },
        setPostingCustomOptions(options: PostingCustomOptions) {
            this.postingCustomOptions = options;
        },
        setIsAuthenticated(authenticatedState: boolean) {
            this.isAuthenticated = authenticatedState;
        },
        setSelectedSupplierAccount(selectedAccount: string | null) {
            this.selectedSupplierAccount = selectedAccount;
        },
        setSelectedPayrollAccount(selectedAccount: string | null) {
            this.selectedPayrollAccount = selectedAccount;
        },
        setSelectedMandateAccount(selectedAccount: string | null) {
            this.selectedMandateAccount = selectedAccount;
        },
        setRefreshUsers(refresh: boolean) {
            this.refreshUsers = refresh;
        },
        setRegistrationStep(step: number) {
            this.registrationStep = step;
        },
        setRegistrationCompanyDetails(companyDetails: CompanyDetails | null) {
            this.registrationCompanyDetails = companyDetails;
        },
        setValidateCompanyDetails(valid: boolean) {
            this.validateCompanyDetails = valid;
        },
        setRegistrationAccountUsage(accountUsage: AccountUsage | null) {
            this.registrationAccountUsage = accountUsage;
        },
        setValidateAccountUsage(valid: boolean) {
            this.validateAccountUsage = valid;
        },
        setHideRegistrationControls(hide: boolean) {
            this.hideRegistrationControls = hide;
        },
        setShowStakeholdersControls(show: boolean) {
            this.showStakeholdersControls = show;
        },
        setStakeholders(stakeholdersList: Stakeholder[] | []) {
            this.stakeholders = stakeholdersList;
        },
        addStakeholder(stakeholder: Stakeholder) {
            this.stakeholders.push(stakeholder);
        },
        deleteStakeholder(index: number) {
            this.stakeholders.splice(index, 1);
        },
        setAddress(address: null | personalAddressAttributes) {
            this.address = address;
        },
        setRegistrationDocuments(docs: Documents | null) {
            this.registrationDocuments = docs;
        },
        setValidateDocuments(valid: boolean) {
            this.validateDocuments = valid;
        },
        setRefreshBacsFiles(refresh: boolean) {
            this.refreshBacsFiles = refresh;
        },
        setSubmitRegistrationApplication(submit: boolean) {
            this.submitRegistrationApplication = submit;
        },
        setRegistrationBankAccounts(accounts: BankAccount[]) {
            this.registrationBankAccounts = accounts;
        },
        setLoadingRegistration(loading: boolean) {
            this.loadingRegistration = loading;
        },
        setRefreshBacsTable(refresh: boolean) {
            this.refreshBacsTable = refresh;
        },
    },
    persist: [
        {
            paths: [
                "userRoles",
                "schoolShortcodes",
                "permissions",
                "isHubpay",
                "schoolSettings",
            ],
            storage: localStorage,
        },
        {
            paths: [
                "dateRange",
                "singleDate",
                "loadingTx",
                "invoiceTxList",
                "showPaymentLinkForm",
                "reloadLinkList",
                "loadingPostings",
                "postingsList",
                "postingCustomOptions",
                "isAuthenticated",
                "selectedSupplierAccount",
                "selectedPayrollAccount",
                "selectedMandateAccount",
                "refreshUsers",
                "registrationStep",
                "registrationCompanyDetails",
                "validateCompanyDetails",
                "registrationAccountUsage",
                "validateAccountUsage",
                "hideRegistrationControls",
                "showStakeholdersControls",
                "stakeholders",
                "address",
                "registrationDocuments",
                "validateDocuments",
                "refreshBacsFiles",
                "submitRegistrationApplication",
                "registrationBankAccounts",
                "loadingRegistration",
                "refreshBacsTable",
            ],
            storage: sessionStorage,
        },
    ],
});
