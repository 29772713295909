import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import adminRoutes from "./routes/adminRoutes";
import schoolRoutes from "./routes/schoolRoutes";
import authRoutes from "./routes/authRoutes";
import registrationRoutes from "./routes/registrationRoutes";
import { usePiniaStore } from "../stores/store";
import { checkIfArrayElementsMatch } from "../methods/pathRedirection";
import { Roles } from "@/constants/access";

// adds the admin and school routes to the router
const routes: Array<RouteRecordRaw> = [
    ...authRoutes,
    ...adminRoutes,
    ...schoolRoutes,
    ...registrationRoutes,
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

/**
 * The ts-ignore comment below was added because on build the compiler will throw this
 * typescript error: TS6133: 'from' is declared but its value is never read.
 * But the router plugin requires all 3 parameters to be passed in.
 *  */
// @ts-ignore
router.beforeEach((to, from, next) => {
    // user role
    const pStore = usePiniaStore();
    const storedUserRoles = pStore.getUserRoles;
    // meta route properties
    const isAuthenticated = pStore.getIsAuthenticated;
    const requiresAdminRole = to.matched.some((x) => x.meta.admin);
    const requiresSchoolRole = to.matched.some((x) => x.meta.school);

    const currentUserRoles = storedUserRoles ? storedUserRoles : [];
    const admin = [Roles.ADMIN_ROLE];
    const school = [Roles.ORGANISATION_ROLE];
    const isAdminUser = checkIfArrayElementsMatch(admin, currentUserRoles);
    const isSchoolUser = checkIfArrayElementsMatch(school, currentUserRoles);
    // prevent user from accessing routes that are not available to them
    if (to.path !== "/callback") {
        if (requiresAdminRole) {
            if (isAuthenticated && !isAdminUser) {
                next("/unauthorised-access");
            } else if (!isAuthenticated && isAdminUser) {
                next("/login-redirect");
            } else {
                next();
            }
        } else if (requiresSchoolRole) {
            if (isAuthenticated && !isSchoolUser) {
                next("/unauthorised-access");
            } else if (!isAuthenticated && isSchoolUser) {
                next("/login-redirect");
            } else {
                next();
            }
        } else {
            next();
        }
    }
});

export const redirectTo404 = () => {
    router.push({ name: "404" });
};

export const redirectToAccessDenied = () => {
    router.push({ name: "UnauthorisedAccess" });
};

export const changeRoute = (path: string) => {
    router.push(path).catch((err) => {
        console.log("Route error: " + err);
    });
};

export const redirectToFile = (id: string) => {
    const path = `/school/bacs/files?id=${id}`;
    router.push(path).catch((err) => {
        console.log("Route error: " + err);
    });
};

export default router;
